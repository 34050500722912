:root {
	--primary: rgb(177, 159, 107);
	--secondary: rgb(177, 159, 107);
	--tertiary: #B12704;
	--light: #ffffff;
	--dark: rgb(145, 145, 145);
	--darker: rgb(109, 109, 109);
	--darkest: #111111;
	--error: rgb(252, 99, 99);
	--spacing-one: calc(1rem * 0.5);
	--spacing-two: calc(1rem);
	--spacing-five: calc(2.5rem);
	--coming-soon: rgb(225, 225, 225);
  }

* {
	margin: 0;
}
h1 {
	color: var(--darker)
}
p {
	font-size: 13px;
	font-family: Arial, Helvetica, sans-serif;
	color: var(--dark);
}
ul {
	padding: 0;
	font-family: Arial, Helvetica, sans-serif;
	color: var(--dark);
}
li {
	list-style: none;
	margin-top: 5px;
	cursor: pointer;
	width: fit-content;
	color: var(--dark);
}
img {
	max-width: 100%;
	max-height: 100%;
	cursor: pointer;
}
a {
	text-decoration: none;
	cursor: pointer;
	color: var(--dark);
}

#top {
	position: absolute;
	top: 0;
}

.display-none{
	display: none;
}

.coming-soon {
	color: var(--coming-soon);
	width: fit-content;
}

.selected-nav {
	text-decoration: underline;
	color: black;
}

.lynn-carlin-art-container {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin: 10px 20px 20px 20px;
	font-family: 'Montserrat', sans-serif;
}

.nav-container {
	/* display: none; */
	margin-top: 10px;
	margin-bottom: 25px;
}

.nav-container ul {
	padding-left: 20px;
	font-size: 13px;
}

.inner-nav-dropdown-container {
	margin-bottom: 10px;
}



.portfolio-container {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	background-color: var(--light);

}

.portfolio-img-container {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	align-items: flex-end;
	width: 80%;
	max-height: 100%;
	margin-left: 10px;
	margin-right: 10px;
}

.portfolio-img-info {
	width: 100%;
	/* padding-left: 20px; */
	margin-top: 10px;
	margin-bottom: 30px;
}

.modalBackdrop {
	background: var(--light);
	overflow: auto;
	position: fixed;
	top: 0;
	z-index: 999;
	width: 100%;
	height: 100%;
}

.modalContainer {
	background: var(--light);
	border-radius: 5px;
	max-width: 90%;
	margin: 0px auto 0px auto;
	/* padding: 15px 15px 0 15px; */
	width: 960px;
	display: flex;
	justify-content: space-around;
	align-items: center;
	height: fit-content;
	min-height: 70%;
	padding-right: 0;
}

.modal-img {
	width: 90%;
	margin-left: 2vw;
	margin-right: 2vw;
}

.modal-img-info {
	padding-left: 10%;
	margin-top: 10px;
}

.children-playing-text-container {
	text-align: center;
	margin: 30px;
	padding-left: 20px;
	padding-right: 20px;
}
.children-playing-text-container p{
	text-align: center;
	font-family: Arial, Helvetica, sans-serif;
	font-size: 15px;

}

.faX-div {
	display: flex;
	justify-content: flex-end;
}

.faX {
	margin: 10px;
	margin-right: 20px;
	font-size: 5vw;
	cursor: pointer;
	color: var(--dark);
}
.faX:hover {
	opacity: .5;
}

.faBars {
	position: fixed;
	right: 0;
	z-index: 99;
	margin-right: 20px;
	margin-top: 10px;
	/* background-color: rgba(255, 255, 255, .5); */
	padding: 2px;
	border-radius: 5px;
	font-size: 18px;
	cursor: pointer;
	color: var(--dark);
}


.faAngle {
	font-size: 9vw;
	cursor: pointer;
	color: var(--dark);
}
.faAngle:hover {
	opacity: .5;
}

.display-icons {
	font-size: 30px;
	width: 80px;
	display: flex;
	justify-content: space-between;
	position: absolute;
	top: 0;
	right: 0;
	margin-top: 20px;
	margin-right: 10%;
	z-index: 9999;
}

.contact {
	display: flex;
	justify-content: center;
	align-items: flex-end;

}

.contact-fa-container {
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
}

.fa-contact {
	margin-top: 30px;
	width: 100%;
	font-size: 30px;
}
.fa-contact1 {
	width: 100%;
	font-size: 30px;
}

.faTwitter {
	color: rgb(0, 162, 255);
}
.faFacebook {
	color:rgb(66, 103, 178)
}
.faEnvelope {
	color:#EA4335;
}
.faInstagram {
	color: rgb(49, 49, 49);
}

.statement {
	margin-top: 30px;
}

.statement img {
	margin-top: 70px;
	margin-bottom: 20px;
	box-shadow: none;
}
.statement-container {
	margin-right: 20px;
	margin-left: 20px;
}
.statement-container p {
	font-size: 15px;
}
footer {
	width: 100%;
	text-align: center;
	margin-top: 40px;
	padding-bottom: 20px;
}
footer p {
	margin-bottom: 10px;
	color:rgb(190, 190, 190);
}
.contact-footer {
	position: absolute;
	bottom: 0;
}
.AidanMeyer {
	color:rgb(228, 228, 228);
}

.root {
	height: 100vh;
}

.statement footer {
	display: block;
	/* position: absolute;
	bottom: 0; */
}

.position-absolute {
	position: absolute;
	bottom: 0;
}

@media screen and (min-width: 823px) {
	/* main portfolio start */
	.root {
		display: flex;
		justify-content: space-around;
		gap: 3rem;
	}
	header {
		width: fit-content;
		max-width: none;
		height: 100%;
		display: flex;
		/* justify-content: flex-start; */
		justify-content: center;
		align-items: center;
		margin: 0;
		padding-left: 7%;
		/* display: flex;
		width: fit-content;
		align-items: center;
		margin-right: 2vw; */
	}
	header h1 {
		font-size: 50px;
	}
	header li {
		font-size: 15px;
	}

	.statement-container {
		display: initial !important;
		margin: 0;
	}

	.statement {
		padding-right: 7%;
		width: 50%;
		/* margin-right: 50px; */
	}
	.lynn-carlin-art-container {
		margin-top: 0;
		margin-right: 0;
	}
	main {
		width: 60%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		padding-right: 7%
	}
	.portfolio {
		max-height: 70%;
		overflow-y:scroll;
		/* margin-right: 20px; */
		box-shadow: 3px 5px 20px rgb(0, 0, 0);
	}

	.portfolio-container {
		/* justify-content: flex-start; */
		padding: 1.5rem;
		gap: 1.5rem;
	}
	.portfolio-img-container {
		max-width: 250px;
		/* padding-top: 40px;
		margin-right: 40px; */
		
	}
	img {
		box-shadow: 3px 5px 20px rgb(0, 0, 0);
		
	}
	.portfolio-img-info {
		text-align: center;
		margin-top: 20px;
	}

	.children-playing-text-container{
		margin: 0;
		padding: 0;
		padding: 1rem;
		position: sticky;
		top: 0;
		right: 0;
		background-color: var(--light);
		/* box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.232); */
		border: solid black .05rem;
	}

	::-webkit-scrollbar {
		-webkit-appearance: none;
		width: 11px;
	  }
	  
	  ::-webkit-scrollbar-thumb {
		border-radius: 40px;
		border: 2px solid var(--light);
		background-color: rgb(230, 230, 230);
		box-shadow: 0 0 1px rgba(255, 255, 255, .5);
	  }

	  /* main portfolio end */

	  /* modal start */

	  .faX-div {
		  justify-content: flex-start;
	  }
	  .modalContainer {
		  /* padding-right: 5%; */
	  }
	  .modal-img {
		  max-height: 600px;
		  width: auto;
		  box-shadow: none;
	  }
	  .modal-img-info {
		  padding: 0;
		  text-align: center;
		  /* padding-right: 5%; */
	  }
	  .faX-div {
		  justify-content: flex-end;
		  padding-right: 100px;
	  }
	  .faX {
		  font-size: 40px;
	  }
	  .contact {
		  align-items: center;
		  margin-right: 50px;
	  }
	  .fa-contact {
		  margin-top: 0;
		  margin-bottom: 30px;
		  font-size: 50px;
	  }
	  .faEnvelope {
		  cursor: pointer;
	  }
	  .faAngle {
		  font-size: 60px;
	  }
	  footer {
		position:  absolute;
		bottom: 0;
		right: 0;
		  display: flex;
		  justify-content: space-around;
	  }
	  .contact footer {
		margin-bottom: 10px;
		right: 0;
	}
	
	  .faTwitter:hover {
		  color: var(--dark)
	  }

	  .cover-img {
		  max-width: 65%;
	  }
	  .statement-img-container {
		  display: flex;
		  flex-direction: column;
		  /* width: 100%; */
		  /* justify-content: flex-end; */
		  justify-content: center;
		  /* margin-bottom: 50px; */
	  }
	  .statement img {
		max-width: none;
		max-height: none;
		width: 100%;
		height: auto;
		margin: 0;
	  }
	  .statement p {
		  text-align: center;
		  /* max-width: 65%; */

	  }
	  .statement-p-container {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
	  }

	  .statement-footer-div {
		  display: block;
		  margin-top: 20000px;
		  width: 100%;
	  }

	  .statement footer {
		  position: absolute;
		  bottom: 0;
		  right: 0;
		  padding-bottom: 5px;
		  display:  flex;
		  margin-top: 110vh !important;
	  }

	  .about-footer {
		/* display: block; */
		position: absolute;
		width: 100%;
		/* bottom: 0; */
		margin-top: 110vh;
		padding-bottom: 30px;
	  }

	  .statement {
		  height: 100vh;
		  display: flex;
		  align-items: center;
		  margin-top: 0;
		  /* align-content: space-between; */
	  }
	  


	}
	@media screen and (max-width: 822px) {
		.landing-footer {
			display: block;
		}
		.position-absolute {
			position: relative;
		}
	}
/* 
	@media screen and (max-width: 500px) {
		.landing-footer {
			display: block;
		}
		.position-absolute {
			position: absolute;
		}
	} */